import { GartnerFooter } from "gx-npm-ui";
import FileHubHeaderComponent from "../../sections/file-hub-header/file-hub-header.component";
import FileListComponent from "../../sections/file-list/file-list.component";
import RecommendedCardsComponent from "../../sections/recommended-cards/recommended-cards.component";
import styles from "./stand-alone-view.styles.module.scss";
import { useScrolling } from "gx-npm-lib";
import classNames from "classnames";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";
import { useEffect, useState } from "react";

const StandAloneViewComponent = () => {
  const isScrolling = useScrolling();
  const [isStackedView, setIsStackedView] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsStackedView(window.innerWidth < 1240);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={styles.standAloneRoot}>
      <FileHubHeaderComponent />
      {isStackedView && (
        <div className={classNames(styles.standAloneContent, isScrolling && styles.scroll)}>
          <div className={styles.sidebarTop}>
            <FileDragAndDropUploadComponent />
          </div>
          <div className={styles.mainColumn}>
            <FileListComponent />
          </div>
          <div className={styles.sidebarBottom}>
            <RecommendedCardsComponent />
          </div>
        </div>
      )}
      {!isStackedView && (
        <div className={classNames(styles.standAloneContent, isScrolling && styles.scroll)}>
          <div className={styles.leftRail}>
            <div className={styles.sidebarTop}>
              <FileDragAndDropUploadComponent />
            </div>
            <div className={styles.sidebarBottom}>
              <RecommendedCardsComponent />
            </div>
          </div>
          <div className={styles.mainColumn}>
            <FileListComponent />
          </div>
        </div>
      )}
      <GartnerFooter hasNavbar={false} />
    </section>
  );
};

export default StandAloneViewComponent;
