import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultCircleColor = colorPalette.status.poisonCherry.hex;
const defaultTriangleColor = colorPalette.status.darkLemon.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ExclamationCircledIcon = ({
  fillPath = defaultCircleColor,
  fillSvg = "none",
  height = "20",
  title = "",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
        fill={fillPath}
      />
    </svg>
  );
};
export const ExclamationCircledIconV2 = ({
  fillPath = defaultCircleColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ExclamationTriangledIcon = ({
  fillPath = defaultTriangleColor,
  fillSvg = "none",
  height = "18",
  title = "",
  viewBox = "0 0 20 18",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M2.47012 18H17.5301C19.0701 18 20.0301 16.33 19.2601 15L11.7301 1.98999C10.9601 0.659993 9.04012 0.659993 8.27012 1.98999L0.740121 15C-0.0298788 16.33 0.930121 18 2.47012 18ZM10.0001 11C9.45012 11 9.00012 10.55 9.00012 9.99999V7.99999C9.00012 7.44999 9.45012 6.99999 10.0001 6.99999C10.5501 6.99999 11.0001 7.44999 11.0001 7.99999V9.99999C11.0001 10.55 10.5501 11 10.0001 11ZM11.0001 15H9.00012V13H11.0001V15Z"
        fill={fillPath}
      />
    </svg>
  );
};
