import React, { FC, useState } from "react";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import styles from "./kebab-menu.styles.module.scss";
import { DeleteTrashcanIcon, KebabSingleIcon } from "gx-npm-icons";
import { IconButton, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

type KebabMenuProps = {
  onClickDownload: () => void;
  onClickDelete: () => void;
  disabled?: boolean;
  onOpen: () => void;
  onClose: () => void;
  isSurvey?: boolean;
};

const KebabMenu: FC<KebabMenuProps> = ({
  onClickDownload,
  onClickDelete,
  disabled = false,
  isSurvey,
  onOpen,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <div className={styles.kebabMenu}>
      <IconButton ariaLabel="options" onClick={handleClick} disabled={disabled} hover={Boolean(anchorEl)}>
        <KebabSingleIcon />
      </IconButton>
      <Menu
        className={styles.listMenu}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 220,
              width: "20ch",
            },
          },
        }}
      >
        <MuiMenuItem
          className={styles.menuList}
          onClick={() => {
            onClickDownload();
            handleClose();
          }}
        >
          <TypographyComponent styling="p3" boldness="medium">
            {t("Download")}
          </TypographyComponent>
        </MuiMenuItem>
        <MuiMenuItem
          disabled={isSurvey}
          className={styles.menuList}
          onClick={() => {
            onClickDelete();
            handleClose();
          }}
        >
          <DeleteTrashcanIcon />
          <TypographyComponent styling="p3" boldness="medium">
            {t("Delete")}
          </TypographyComponent>
        </MuiMenuItem>
      </Menu>
    </div>
  );
};

export default KebabMenu;
