import { TextLink, TypographyComponent } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ACCEPTED_FILE_EXTENSIONS, MAX_FILE_MB_SIZE } from "../file-drag-and-drop-upload.constants";
import styles from "./upload-instructions.styles.module.scss";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { useParams } from "react-router-dom";

type UploadInstructionsProps = { onClickLink: () => void };
const UploadInstructionsComponent = ({ onClickLink = () => {} }: UploadInstructionsProps) => {
  const { t } = useTranslation();
  const { initId = "" } = useParams<{ initId: UUID }>();
  const { role, hasLoadedAccess } = useUserInitAccess(initId);
  const isViewer = hasLoadedAccess && role === InitUserRole.VIEWER;
  return (
    <Fragment>
      <TypographyComponent
        boldness="medium"
        color="carbon"
        element="h3"
        rootClassName={styles.instructionsHeader}
        styling="p3"
      >
        {t("Add files")}
      </TypographyComponent>
      <div className={styles.instructionsSubTextOne}>
        <TypographyComponent boldness="regular" styling="p3">
          {t("Drag and drop or")}
        </TypographyComponent>
        <TextLink
          ariaLabel={t("upload files")}
          onClick={onClickLink}
          rootClassName={styles.instructionsLink}
          text={t("choose a file")}
          disabled={isViewer}
        />
      </div>
      <div className={styles.instructionsSubtextTwo}>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={styles.instructionsFileTypeTitle}
          styling="p4"
        >
          {t("File types")}
        </TypographyComponent>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={styles.instructionsFileTypes}
          styling="p4"
        >
          ({ACCEPTED_FILE_EXTENSIONS})
        </TypographyComponent>
        <TypographyComponent color="iron" element="span" rootClassName={styles.instructionsSpacer} styling="p4">
          {`|`}
        </TypographyComponent>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={styles.instructionsFileSize}
          styling="p4"
        >
          {`${t("Max size")} ${MAX_FILE_MB_SIZE}MB`}
        </TypographyComponent>
      </div>
    </Fragment>
  );
};

export default UploadInstructionsComponent;
