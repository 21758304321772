const VendorProvidedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#4A4644" />
      <path
        d="M7.2844 7H16.6691C16.8107 7 16.9295 7.04794 17.0252 7.14383C17.1211 7.23972 17.1691 7.3585 17.1691 7.50017C17.1691 7.64194 17.1211 7.76067 17.0252 7.85633C16.9295 7.95211 16.8107 8 16.6691 8H7.2844C7.14273 8 7.02401 7.95206 6.92823 7.85617C6.83234 7.76028 6.7844 7.6415 6.7844 7.49983C6.7844 7.35806 6.83234 7.23933 6.92823 7.14367C7.02401 7.04789 7.14273 7 7.2844 7ZM7.41256 17.3333C7.2419 17.3333 7.09879 17.2756 6.98323 17.1602C6.86779 17.0446 6.81006 16.9015 6.81006 16.7308V13.3333H6.60373C6.41395 13.3333 6.25773 13.2592 6.13506 13.1108C6.0124 12.9626 5.97329 12.7936 6.01773 12.6038L6.6844 9.47567C6.71484 9.33689 6.78573 9.22294 6.89706 9.13383C7.0084 9.04461 7.13495 9 7.27673 9H16.6767C16.8185 9 16.9451 9.04461 17.0564 9.13383C17.1677 9.22294 17.2386 9.33689 17.2691 9.47567L17.9357 12.6038C17.9802 12.7936 17.9411 12.9626 17.8184 13.1108C17.6957 13.2592 17.5395 13.3333 17.3497 13.3333H17.1434V16.8333C17.1434 16.975 17.0955 17.0937 16.9996 17.1895C16.9037 17.2854 16.7849 17.3333 16.6432 17.3333C16.5015 17.3333 16.3827 17.2854 16.2871 17.1895C16.1913 17.0937 16.1434 16.975 16.1434 16.8333V13.3333H13.1434V16.7308C13.1434 16.9015 13.0857 17.0446 12.9702 17.1602C12.8547 17.2756 12.7116 17.3333 12.5409 17.3333H7.41256ZM7.81006 16.3333H12.1434V13.3333H7.81006V16.3333Z"
        fill="white"
      />
    </svg>
  );
};

export default VendorProvidedIcon;
