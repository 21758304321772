const ClearIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9724_8838)">
        <circle cx="12" cy="12" r="12" fill="white" />
        <path
          d="M17.7752 6.23432C17.4177 5.87682 16.8402 5.87682 16.4827 6.23432L12.0002 10.7077L7.51766 6.22516C7.16016 5.86766 6.58266 5.86766 6.22516 6.22516C5.86766 6.58266 5.86766 7.16016 6.22516 7.51766L10.7077 12.0002L6.22516 16.4827C5.86766 16.8402 5.86766 17.4177 6.22516 17.7752C6.58266 18.1327 7.16016 18.1327 7.51766 17.7752L12.0002 13.2927L16.4827 17.7752C16.8402 18.1327 17.4177 18.1327 17.7752 17.7752C18.1327 17.4177 18.1327 16.8402 17.7752 16.4827L13.2927 12.0002L17.7752 7.51766C18.1235 7.16932 18.1235 6.58266 17.7752 6.23432Z"
          fill="#676565"
        />
      </g>
      <defs>
        <clipPath id="clip0_9724_8838">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClearIcon;
