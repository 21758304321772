import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import {
  FileTypeDocIcon,
  FileTypeDocxIcon,
  FileTypeJPegIcon,
  FileTypeMovIcon,
  FileTypeMP4Icon,
  FileTypePdfIcon,
  FileTypePNGIcon,
  FileTypePPTIcon,
  FileTypePPTxIcon,
  FileTypeTextIcon,
  FileTypeXlsIcon,
  FileTypeXlsxIcon,
} from "gx-npm-icons";
import {
  getAsyncRequest,
  InitProdState,
  InitUserRole,
  postAsyncRequest,
  useScrolling,
  useUserInitAccess,
  useUserState,
  UUID,
} from "gx-npm-lib";
import { IconButton, TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppCustomEvent } from "../../app.constants";
import DownloadIcon from "../download.icon";
import DeleteDialogComponent from "./delete-dialog/delete-dialog.component";
import KebabMenuComponent from "./kebab-menu/kebab-menu.component";
import ProductSelectComponent from "./product-select/product-select.component";
import EmptyFileListSVG from "./empty-file-list.svg";
import InfoOutlineIcon from "./info-outline.icon";
import VendorProvidedIcon from "./vendor-provided.icon";
import { InitFileDto, InitFileGetResponse, ProductDto } from "./file-list.types";
import styles from "./file-list.styles.module.scss";

const HEADER_STICKY_DEPTH = 86;
const FileListComponent = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<InitFileDto[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [openKebabFileId, setOpenKebabFileId] = useState<UUID>("");
  const [openProductSelectFileId, setOpenProductSelectFileId] = useState<UUID>("");
  const [productsInEval, setProductsInEval] = useState<ProductDto[]>([]);
  const [productsNotInEval, setProductsNotInEval] = useState<ProductDto[]>([]);
  const [hoveredRowFileId, setHoveredRowFileId] = useState<UUID>("");
  const { initId = "", initProdId = "" } = useParams<{ initId: UUID; initProdId: UUID }>();
  const { role } = useUserInitAccess(initId);
  const { firstName, lastName } = useUserState();
  const isListHeaderStuck = useScrolling(HEADER_STICKY_DEPTH);

  useEffect(() => {
    const handleCustomEvent = (event: CustomEvent) => {
      if (!event.detail) {
        return;
      }
      setFiles((prevState) => [
        {
          fileId: event.detail.fileId,
          fileName: event.detail.fileName,
          initProductId: event.detail.initProdId,
          surveyId: "",
          surveyProductDocId: "",
          surveyRequestedDocName: "",
          uploadDate: new Date(),
          uploaderEmail: "",
          uploaderName: `${firstName} ${lastName}`,
        },
        ...prevState,
      ]);
    };

    window.addEventListener(AppCustomEvent.SUCCESSFUL_FILE_UPLOADED, handleCustomEvent);
    return () => {
      window.removeEventListener(AppCustomEvent.SUCCESSFUL_FILE_UPLOADED, handleCustomEvent);
    };
  }, [firstName, lastName]);

  useEffect(() => {
    if (!initId) {
      return;
    }
    (async () => {
      const url = initProdId
        ? `/api/v2/initiatives/${initId}/files/product/${initProdId}`
        : `/api/v2/initiatives/${initId}/files`;
      try {
        const response: InitFileGetResponse = await getAsyncRequest(url);
        if (response.status !== 200 || !Array.isArray(response.data.data.files)) {
          throw new Error();
        }
        setFiles(response.data.data.files);
        setProductsInEval(
          response.data.data.products.filter((prod) => {
            return [InitProdState.IN_EVALUATION, InitProdState.AWARDED].includes(prod.state);
          })
        );
        setProductsNotInEval(
          response.data.data.products.filter((prod) => {
            return [InitProdState.LISTED, InitProdState.SCREENED_OUT].includes(prod.state);
          })
        );
      } catch (error) {
        setHasError(true);
      }
    })();
  }, [initId, initProdId]);

  const handleChangeProduct = (fileId: UUID, initProductId: UUID) => {
    setFiles((prev) => {
      return prev.map((file) => (file.fileId !== fileId ? file : { ...file, initProductId }));
    });
  };

  // todo this logic could be moved to delete dialog
  const handleClickDelete = (fileId: UUID) => {
    (async () => {
      try {
        const response = await postAsyncRequest(`/api/v2/initiatives/${initId}/file/${fileId}/delete`);
        if (response.status !== 200) {
          throw new Error();
        }
        setFiles((prev) => prev.filter((file) => file.fileId !== fileId));
      } catch (error) {
        setHasError(true);
      }
    })();
  };

  // todo implement commented out code
  const handleClickDownload = (_fileId: UUID) => {
    // const file = files.find((f) => f.fileId === fileId);
    // const url = !file?.surveyId
    //   ? `/api/v2/initiatives/${initId}/file/${fileId}/download`
    //   : `/api/v2/initiatives/${initId}/survey-documents/evaluator/${file.surveyId}/${file.initProductId}/documents/${file.surveyProductDocId}/download`;
    //
    // (async () => {
    //   try {
    //     const response = await getAsyncRequest(url);
    //     if (response.status !== 200 || !response.data?.data?.signedUrl) {
    //       throw new Error();
    //     }
    //     window.open(response.data.data.signedUrl, "_blank", "noopener");
    //   } catch (error) {
    //     setHasError(true);
    //   }
    // })();
  };

  const handleClickFQLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(`https://www.gartner.com/`, "_blank", "noopener");
  };

  return (
    <div className={styles.fileListRoot}>
      <div className={styles.fileCount}>
        <TypographyComponent styling="p3" boldness="medium">
          {t("Files")} ({files.length})
        </TypographyComponent>
      </div>
      <div className={styles.body}>
        {hasError && <div>{t("TODO ERROR COMPONENT")}</div>}
        <div className={styles.spacer} />
        <table className={styles.fileTable}>
          <thead className={classNames(styles.tableHeader, isListHeaderStuck && styles.stuckHeader)}>
            <tr>
              <th scope="col">
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnName} styling="p4">
                  {t("Name")}
                </TypographyComponent>
              </th>
              <th scope="col">
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnVendor} styling="p4">
                  {t("Vendor")}
                </TypographyComponent>
              </th>
              <th scope="col">
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnOwner} styling="p4">
                  {t("Owner")}
                </TypographyComponent>
              </th>
              <th scope="col">
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnDate} styling="p4">
                  {t("Date added")}
                </TypographyComponent>
              </th>
              <th className={styles.spacerRight} scope="col">
                <div className={styles.hiddenColumnText}>{t("extra column")}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {files.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <div className={styles.emptyState}>
                    <img alt={t("Empty file list")} className={styles.emptyFile} src={EmptyFileListSVG} />
                    <TypographyComponent color="iron" styling="h4">
                      {t("No files added")}
                    </TypographyComponent>
                    <TypographyComponent boldness="regular" color="iron" styling="p3">
                      {t("Your team's file library is empty. Once files are uploaded, they'll appear here.")}
                    </TypographyComponent>
                  </div>
                </td>
              </tr>
            )}
            {files.length > 0 &&
              files.map((file) => {
                const fileParts = file.fileName.split(".");
                const fileName = fileParts.slice(0, -1).join(".");
                const fileExt = fileParts[fileParts.length - 1].toLocaleLowerCase();
                return (
                  <tr
                    key={file.fileId}
                    className={classNames(
                      styles.fileRow,
                      openKebabFileId === file.fileId && styles.selected,
                      openProductSelectFileId === file.fileId && styles.openDropdown
                    )}
                    onMouseEnter={() => setHoveredRowFileId(file.fileId)}
                    onMouseLeave={() => setHoveredRowFileId("")}
                  >
                    <td className={styles.namesContainer}>
                      <div className={styles.fileIcon}>
                        {fileExt === "doc" && <FileTypeDocIcon />}
                        {fileExt === "docx" && <FileTypeDocxIcon />}
                        {(fileExt === "jpeg" || fileExt === "jpg") && <FileTypeJPegIcon />}
                        {fileExt === "mov" && <FileTypeMovIcon />}
                        {fileExt === "mp4" && <FileTypeMP4Icon />}
                        {fileExt === "pdf" && <FileTypePdfIcon />}
                        {fileExt === "png" && <FileTypePNGIcon />}
                        {fileExt === "ppt" && <FileTypePPTIcon />}
                        {fileExt === "pptx" && <FileTypePPTxIcon />}
                        {fileExt === "txt" && <FileTypeTextIcon />}
                        {fileExt === "xls" && <FileTypeXlsIcon />}
                        {fileExt === "xlsx" && <FileTypeXlsxIcon />}
                      </div>
                      <div>
                        <TypographyComponent boldness="regular" styling="p5">
                          {file.surveyRequestedDocName}
                        </TypographyComponent>
                        <div className={styles.namesTypeStyles}>
                          <TypographyComponent boldness="medium" rootClassName={styles.fileName} styling="p3">
                            {fileName}
                          </TypographyComponent>
                          <TypographyComponent boldness="regular" color="iron" styling="p4">
                            {`(.${fileExt})`}
                          </TypographyComponent>
                        </div>
                      </div>
                    </td>
                    <td aria-label={"Select Vendor"} className={styles.vendor}>
                      {/* todo handle view only and survey document views (not allowed to change either) */}
                      <ProductSelectComponent
                        fileId={file.fileId}
                        initProdId={file.initProductId}
                        productsInEval={productsInEval}
                        productsNotInEval={productsNotInEval}
                        onChange={(initProductId: UUID) => handleChangeProduct(file.fileId, initProductId)}
                        onClose={() => {
                          setOpenProductSelectFileId("");
                          setHoveredRowFileId("");
                        }}
                        onOpen={() => setOpenProductSelectFileId(file.fileId)}
                        isRowHovered={hoveredRowFileId === file.fileId}
                      />
                    </td>
                    <td aria-label={t("Owner avatar")}>
                      {file.surveyId && (
                        <TooltipV2
                          placement="top"
                          PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                          title={
                            <div className={styles.vendorToolTipText}>
                              {file.uploaderEmail}
                              {file.uploaderName}
                            </div>
                          }
                        >
                          <div className={styles.vendorProvidedIcon}>
                            <VendorProvidedIcon />
                          </div>
                        </TooltipV2>
                      )}
                      {!file.surveyId && (
                        <UsernameDisplay
                          isSmallerIcon={true}
                          name={file.uploaderName}
                          rootClassName={styles.userNameIcon}
                          showName={false}
                        />
                      )}
                    </td>
                    <td>
                      <TypographyComponent boldness="regular" color="carbon" rootClassName={styles.date} styling="p4">
                        {new Date(file.uploadDate).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </TypographyComponent>
                    </td>
                    <td aria-label={"kebab menu"}>
                      {![InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
                        <IconButton ariaLabel={t("options")} onClick={() => handleClickDownload(file.fileId)}>
                          <DownloadIcon fill={colorPalette.neutrals.iron.hex} />
                        </IconButton>
                      )}
                      {[InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
                        <KebabMenuComponent
                          isSurvey={!!file.surveyId}
                          onClickDownload={() => handleClickDownload(file.fileId)}
                          onClickDelete={() => setIsDeleteDialogOpen(true)}
                          onClose={() => setOpenKebabFileId("")}
                          onOpen={() => setOpenKebabFileId(file.fileId)}
                        />
                      )}
                      <DeleteDialogComponent
                        fileName={fileName}
                        isLoading={false}
                        isOpen={isDeleteDialogOpen}
                        onClose={() => setIsDeleteDialogOpen(false)}
                        onConfirm={() => handleClickDelete(file.fileId)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className={styles.info}>
        <div className={styles.infoIcon}>
          <InfoOutlineIcon />
        </div>
        <TypographyComponent boldness="regular" color="coal" styling="p5">
          {t(
            "Files uploaded by vendors are not reviewed by Gartner Experts and do not " +
              "necessarily represent the views or opinions of Gartner. Check out our " +
              "FAQ on storing client documents "
          )}
          <a href="https://www.gartner.com" onClick={handleClickFQLink}>
            <TypographyComponent boldness="regular" color="defaultCta" element="span" styling="p5">
              {t("here.")}
            </TypographyComponent>
          </a>
        </TypographyComponent>
      </div>
    </div>
  );
};

export default FileListComponent;
