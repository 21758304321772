import { Fragment } from "react";
import { Button, ButtonLoader, Dialog, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

import styles from "./delete-dialog.styles.module.scss";

type DeleteDialogProps = {
  fileName: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteDialog = ({
  fileName = "",
  onClose = () => {},
  onConfirm = () => {},
  isLoading = false,
  isOpen = false,
}: DeleteDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      handleClose={onClose}
      title={t("Delete file")}
      body={
        <Fragment>
          <TypographyComponent styling="p3" rootClassName={styles.bodyText}>
            <TypographyComponent>
              {t(`Are you sure you want to delete ${fileName}? This File will be permanently deleted.`)}
            </TypographyComponent>
          </TypographyComponent>
        </Fragment>
      }
      footer={
        <div className={styles.cancelBtn}>
          <Button onClick={onClose} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <div className={styles.confirmBtn}>
            <ButtonLoader
              btnClass="primary-destructive-btn"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {t("DELETE")}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};

export default DeleteDialog;
