import { ListSubheader, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import classNames from "classnames";
import { postAsyncRequest, UUID } from "gx-npm-lib";
import { IconButton, ProductLogo, SnackbarBanner, TextLink, TypographyComponent } from "gx-npm-ui";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ProductDto } from "../file-list.types";
import ClearIcon from "./clear.icon";
import styles from "./product-select.styles.module.scss";
import { navigateToUrl } from "single-spa";

type ProductSelectProps = {
  fileId: UUID;
  initProdId: UUID;
  onChange: (initProdId: UUID) => void;
  onClose: () => void;
  onOpen: () => void;
  productsInEval: ProductDto[];
  productsNotInEval: ProductDto[];
  isRowHovered: boolean;
};
const ProductSelectComponent = ({
  fileId,
  initProdId,
  onChange = (_initProdId: UUID) => {},
  onClose = () => {},
  onOpen = () => {},
  productsInEval = [],
  productsNotInEval = [],
  isRowHovered = false,
}: ProductSelectProps) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{
    vertical: "bottom" | "top";
    horizontal: "left" | "right";
  }>({ vertical: "bottom", horizontal: "left" });
  const { initId = "" } = useParams<{ initId: UUID }>();
  const matchingProduct = [...productsInEval, ...productsNotInEval].find((prod) => prod.initProductId === initProdId);
  const isProductsEmpty = [...productsInEval, ...productsNotInEval].length === 0;

  const updateProduct = async (initProductId: UUID) => {
    const url = `/api/v2/initiatives/${initId}/file/${fileId}/update`;
    const response = await postAsyncRequest(url, { initProductId });
    if (response.status === 201) {
      onChange(initProductId);
    } else {
      setHasError(true);
    }
  };

  const handleChangeProduct = (event: SelectChangeEvent<UUID>) => {
    if (isProductsEmpty) {
      return;
    }
    const initProductId = event.target.value as UUID;
    updateProduct(initProductId);
  };
  const handleClearProduct = () => {
    setIsOpen(false);
    updateProduct("");
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    if (targetRect.bottom + 420 > viewportHeight) {
      setMenuPosition({ vertical: "top", horizontal: "left" });
    } else {
      setMenuPosition({ vertical: "bottom", horizontal: "left" });
    }
    setIsOpen(true);
    onOpen();
  };
  const handleGoToVendorList = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToUrl(`/s/evaluation/${initId}/products`);
  };

  return (
    <Fragment>
      <Select
        className={classNames(styles.productSelectContainer, isOpen && styles.open)}
        defaultValue=""
        displayEmpty={true}
        IconComponent={() => null}
        MenuProps={{
          anchorOrigin: menuPosition,
          PaperProps: {
            style: {
              marginLeft: 0,
              marginTop: menuPosition.vertical === "bottom" ? 2 : -2,
            },
          },
          transformOrigin: { horizontal: "left", vertical: menuPosition.vertical === "bottom" ? "top" : "bottom" },
        }}
        onChange={(event: SelectChangeEvent<UUID>) => handleChangeProduct(event)}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={(selected: UUID) => {
          const selectedProduct = [...productsInEval, ...productsNotInEval].find((prod) => {
            return prod.initProductId === selected;
          });
          if (!selectedProduct) {
            return (
              <TypographyComponent
                boldness="regular"
                rootClassName={classNames(styles.placeholderText, isRowHovered && styles.visiblePlaceholderText)}
                color={"iron"}
                styling="p4"
              >
                {t("Select Vendor")}
              </TypographyComponent>
            );
          }
          return (
            <div className={styles.selectedProductWrapper}>
              <ProductLogo
                imageHeight="24px"
                imageWidth="24px"
                logo={selectedProduct.imageLoc}
                name={selectedProduct.name}
              />
              <TypographyComponent
                boldness="regular"
                color="carbon"
                rootClassName={styles.selectedProductName}
                styling="p4"
              >
                {selectedProduct.name}
              </TypographyComponent>
              {isOpen && (
                <IconButton
                  ariaLabel={t("clear selection")}
                  className={styles.clearSelection}
                  focusBackgroundColor={"transparent"}
                  hoverBackgroundColor={"transparent"}
                  width={24}
                  height={24}
                  onClick={handleClearProduct}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          );
        }}
        value={matchingProduct ? initProdId : ""}
      >
        {isProductsEmpty && (
          <div className={styles.noVendorsContainer}>
            <TypographyComponent boldness="regular" color="iron" styling="p3">
              {t("There are no vendors in the vendor list.")}
            </TypographyComponent>
            <TextLink
              text={t("Go to vendor list")}
              onClick={handleGoToVendorList}
              rootClassName={styles.vendorsNavLink}
            />
          </div>
        )}
        {!isProductsEmpty && (
          <ListSubheader className={styles.listSubheader}>
            <TypographyComponent boldness="medium" color="coal" styling="p3">
              {t("Vendors in my evaluation")}
            </TypographyComponent>
          </ListSubheader>
        )}
        {!isProductsEmpty && productsInEval.length === 0 && (
          <div className={styles.noAvailableVendor}>
            <TypographyComponent boldness="regular" styling="p4" color={"iron"}>
              {t("No available vendors")}
            </TypographyComponent>
          </div>
        )}
        {productsInEval.map((prod) => (
          <MenuItem key={prod.initProductId} className={styles.menuItem} value={prod.initProductId}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={prod.imageLoc} name={prod.name} />
            <TypographyComponent boldness="medium" styling="p3">
              {prod.name}
            </TypographyComponent>
          </MenuItem>
        ))}
        {!isProductsEmpty && (
          <ListSubheader className={styles.listSubheader}>
            <TypographyComponent boldness="medium" color="coal" styling="p3">
              {t("Other vendors")}
            </TypographyComponent>
          </ListSubheader>
        )}
        {!isProductsEmpty && productsNotInEval.length === 0 && (
          <div className={styles.noAvailableVendor}>
            <TypographyComponent boldness="regular" styling="p4" color={"iron"}>
              {t("No available vendors")}
            </TypographyComponent>
          </div>
        )}
        {productsNotInEval.map((prod) => (
          <MenuItem key={prod.initProductId} className={styles.menuItem} value={prod.initProductId}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={prod.imageLoc} name={prod.name} />
            <TypographyComponent boldness="medium" color="carbon" styling="p3">
              {prod.name}
            </TypographyComponent>
          </MenuItem>
        ))}
      </Select>
      <SnackbarBanner setIsOpen={() => setHasError(false)} isDefaultErrorMessage={true} isOpen={hasError} />
    </Fragment>
  );
};

export default ProductSelectComponent;
