import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { useScrolling, UUID } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import styles from "./file-hub-header.styles.module.scss";

const FileHubHeaderComponent = () => {
  const { t } = useTranslation();
  const [backBtnRoute, setBackBtnRoute] = useState<string>("overview");
  const [sourcePage, setSourcePage] = useState("overview");
  const { initId = "" } = useParams<{ initId: UUID }>();
  const isScrolling = useScrolling();

  useEffect(() => {
    if (!initId) {
      return;
    }
    const storageKey = `file-hub-source-page-${initId}`;
    const storageValue = sessionStorage.getItem(storageKey);
    if (!storageValue) {
      return;
    }
    setSourcePage(storageValue);
  }, [initId]);

  useEffect(() => {
    if (!sourcePage) {
      return;
    } else if (sourcePage === "overview") {
      setBackBtnRoute("overview");
    } else if (sourcePage === "products") {
      setBackBtnRoute("products");
    } else if (sourcePage === "questionnaire") {
      setBackBtnRoute("questionnaire");
    } else if (sourcePage === "scorecard") {
      setBackBtnRoute("scorecard");
    } else if (sourcePage === "selection") {
      setBackBtnRoute("selection");
    } else if (sourcePage === "checklist") {
      setBackBtnRoute("checklist");
    } else if (sourcePage === "requirements") {
      setBackBtnRoute("requirements");
    } else {
      setBackBtnRoute("overview");
    }
  }, [sourcePage]);

  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initId}/${backBtnRoute}`);
  };

  return (
    <Fragment>
      <div className={styles.spacer} />
      <div className={classNames(styles.brandingHeaderRoot, isScrolling && styles.scroll)}>
        <div className={classNames(styles.brandingHeaderContent)}>
          <div
            className={styles.link}
            onClick={handleClick}
            onKeyDown={(e) => e.key === "Enter" && handleClick()}
            role="link"
            tabIndex={0}
          >
            <ArrowBackIcon fillPath={colorPalette.interactions.defaultCta.hex} />
            <TypographyComponent rootClassName={styles.backText} boldness="semi" color={"defaultCta"}>
              {t("BACK TO EVALUATION")}
            </TypographyComponent>
          </div>
          <BuySmartLogoOrangeStacked />
        </div>
        <div />
      </div>
      <div className={classNames(styles.title, isScrolling && styles.hidden)}>
        <TypographyComponent boldness="medium" color="carbon" styling="p1">
          {t("Evaluation files")}
        </TypographyComponent>
      </div>
    </Fragment>
  );
};

export default FileHubHeaderComponent;
